<!--
 * @Descripttion: 滑块组件
 * @Author: zhanghang
 * @Date: 2021-12-08 17:28:39
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-12-17 18:11:03
-->
<template>
  <div class="slider-wrapper">
    <div class="slide-box" ref="wrapper">
      <div class="plan"></div>
      <div class="float-plan" :style="{ width: rate }"></div>
      <div class="tick-box" v-if="isShowMarks">
        <div
          class="tick-box-item"
          :class="{ 'margin-left': index === marksArray.length - 1 }"
          :style="{ left: item.rate + '%' }"
          v-for="(item, index) in marksArray"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <div class="line"></div>
        </div>
      </div>
      <div class="slider" ref="slider" @mousedown="mouseDown">
        <span class="text" v-if="isShowTooltip">{{ $value }}</span>
      </div>
    </div>
    <div class="input">
      <el-input-number
        :disabled="isDesign||readOnly||disabled"
        :min="min"
        :max="$limit"
        size="small"
        v-model="$value"
        :step="1"
      ></el-input-number>
    </div>
  </div>
</template>

<script>
export default {
  name: "slider",
  props: {
    //是否设计器
    isDesign: {
      type: Boolean,
      default: false,
    },
    readOnly:{
      type: Boolean,
      default: false,
    },
    disabled:{
      type: Boolean,
      default: false,
    },
    //遗留占比
    carryover: {
      type: Number,
      default: 0,
    },
    // 数值
    value: {
      type: Number,
      default: 0,
    },
    //最小值
    min: {
      type: Number,
      default: 0,
    },
    //最大值
    max: {
      type: Number,
      default: 100,
    },
    //是否限制
    isLimitTotal: {
      type: Boolean,
      default: false,
    },
    //是否互斥
    isMutual: {
      type: Boolean,
      default: false,
    },
    //下标
    index: {
      type: Number,
      default: 0,
    },
    //限制值
    limit: {
      type: Number,
      default: 100,
    },
    //是否展示标记
    isShowMarks: {
      type: Boolean,
      default: true,
    },
    //是否展示Tooltip
    isShowTooltip: {
      type: Boolean,
      default: false,
    },
    //标记对象
    marks: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    marksArray() {
      const arr = [];
      const marks = this.marks;
      Object.keys(marks).forEach((key) => {
        arr.push({
          name: marks[key],
          rate: (((key - this.min) * 100) / (this.max - this.min)).toFixed(0),
        });
      });
      return arr;
    },
    $limit: {
      get() {
        return this.isLimitTotal? this.isMutual? this.limit: this.$value + this.carryover: this.limit;
      },
      set(val) {
        this.$emit("update:value", Number(val));
      },
    },
    $value: {
      get() {
        return this.value?this.value:this.min;
      },
      set(val) {
        this.$emit("update:value", Number(val));
      },
    },
  },
  data() {
    return {
      // 进度百分比
      rate: "0%",
      //进度条
      wrapper: null,
      //进度标记
      slider: null,
    };
  },
  mounted() {
    this.wrapper = this.$refs.wrapper;
    this.slider = this.$refs.slider;
    if (this.$value) this.rate = this.slider.style.left = 100 * (this.$value - this.min) / (this.max - this.min) + "%";
  },
  watch: {
    $value(v) {
      if (v > this.$limit) {
        this.$emit("update:value", Number(this.$limit));
      }
      let slider = this.$refs.slider;
      this.rate = slider.style.left = ((v - this.min) / (this.max - this.min)) * 100 + "%";

      // 如果是互斥
      if (this.isMutual && v >= this.min) this.$emit("mutual", { index: this.index, score: v });
    },
  },
  methods: {
    mouseDown(event) {
      if(this.isDesign||this.readOnly||this.disabled) return false
      event.preventDefault();

      let slider = this.slider;
      let rect = this.wrapper.getBoundingClientRect();

      document.onmousemove = (e) => {
        let sliderX = e.clientX - rect.left;
        if (sliderX < 0) {
          sliderX = 0;
        } else if (sliderX > rect.width) {
          sliderX = rect.width;
        }
        //真实值
        const realValue =
          (sliderX / rect.width) * (this.max - this.min) + this.min;
        //展现整数
        const showValue = realValue.toFixed(0);
        //进度比例
        const rateValue = (sliderX / rect.width) * 100;
        //上限比例
        const limitRate = 100 * (this.$limit - this.min) / (this.max - this.min);

        // 进度条的长度以及 滑块的位置
        this.rate = slider.style.left = realValue >= this.$limit ? limitRate + "%" : rateValue + "%";
        // 进度条数值
        this.$value = showValue >= this.$limit ? this.$limit : showValue;

        //防止抖动偏移
        setTimeout(() => {
          //如果是互斥的
          if (this.isMutual) {
            this.$emit("mutual", { index: this.index, score: this.$value });
          }
        }, 0);
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmousedown = null;
      };
    }
  },
};
</script>
