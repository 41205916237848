<template>
  <div class="ds-img-select-item" @click="itemClick">
    <div class="ds-img-select-item-img" @click.stop>
      <dsf-image :src="item.imageSrc" :preview-src-list="$imgSelect.imgList" />
    </div>
    <div class="ds-img-select-item-title">
      <el-checkbox v-if="$imgSelect.multiple" v-model="select" :disabled="$imgSelect.readOnly || $imgSelect.disabled" @click.native.stop />
      <el-radio v-else v-model="select" :label="true" :disabled="$imgSelect.readOnly || $imgSelect.disabled" @click.native.stop />
      <span>{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImgSelectItem",
  inject: ['$imgSelect'],
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  computed: {
    select: {
      get() {
        if (this.$imgSelect.multiple) {
          return this.$imgSelect.selectValue.indexOf(this.item.value) > -1;
        }
        return this.$imgSelect.selectValue === this.item.value;
      },
      set(v) {
        this.$imgSelect.onSelect(this.item, v);
      }
    }
  },
  methods: {
    itemClick() {
      if (this.$imgSelect.isDesign || this.$imgSelect.readOnly || this.$imgSelect.disabled) return;
      this.select = !this.select;
    }
  }
}
</script>