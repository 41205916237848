/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-09 13:38:15
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-06-29 10:01:22
 */
import radio from './radio';

export default dsf.component({
  name: "DsfAsqQuestionJudge",
  ctrlCaption: "判断题",
  mixins: [radio],
  props: {
    trueText: {
      text: String,
      default: '正确'
    },
    falseText: {
      text: String,
      default: '错误'
    }
  },
  watch: {
    trueText(v){
     if(!v){
       this.$emit('update:trueText','正确')
     }
    },
    falseText(v){
      if(!v){
        this.$emit('update:falseText','错误')
      }
     },
  },
  computed: {
    $items() {
      return [
        {
          text: this.trueText,
          value: '1',
          attach: ''
        },
        {
          text: this.falseText,
          value: '0',
          attach: ''
        }
      ];
    },
  }
});