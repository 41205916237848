import handler from './handler';

dsf.buttons.addButton({
  actionName: "system_question_save",
  caption: "问卷保存",
  text: "问卷保存",
  title: "问卷保存",
  icon: "icon-guanbi2",
  params: [
    { value: "false", desc: "关闭时是否要刷新父页面", default: "false" },
  ],
  moduleName: "问卷",
  groupName: "问卷",
  handler: handler.system_question_save
})