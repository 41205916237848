<!--
 * @Descripttion: 数据汇总
 * @Author: zhanghang
 * @Date: 2022-01-25 10:51:31
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-25 17:15:48
-->
<template>
  <div class="ds-control asq-report-form">
    <div class="search-condition">
      <p>
        <span @click="showCondition = !showCondition"><i class="iconfont icon-icon_filter" />
          <span>筛选条件</span>
          <i :class="{ 'show-condition': showCondition }" class="search-icon el-icon-arrow-down"></i
          ></span>
        <span>
          <DsfButton type="plain" @click="downLoadExcel">导出Excel</DsfButton>
        </span>
      </p>
      <div :class="{ show: showCondition }" class="condition-box">
        <el-form :inline="true" :model="conditions">
          <el-form-item label="答题设备">
            <el-select v-model="conditions.deviceType" placeholder="请选择">
              <el-option label="电脑端" value="pc"></el-option>
              <el-option label="移动端" value="mobile"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调查对象">
            <el-select v-model="conditions.respondentId" placeholder="请选择">
              <el-option v-for="it in opts" :key="it.value" :label="it.text" :value="it.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="middle" label="提交时间">
            <el-date-picker
              v-model="conditions.submit_datetime"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item class="small" label="提交人" v-if="isAnonymity!='1'">
            <el-input v-model="conditions.keywords" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <DsfButton @click="reloadData">查询</DsfButton>
            <DsfButton @click="reset" type="plain">重置</DsfButton>
          </el-form-item>
        </el-form>
        <div class="arrow"></div>
      </div>
    </div>
    <div  style="width: 844px" class="statistics-panle-wrap asq-statistics-table">
      <el-table
        :data="tableData"
        :highlight-current-row="false"
        :cell-style="{ color: '#262F35' }"
        :header-cell-style="{ background: ' #f5f7fa' }"
        border
        style="width: 100%"
      >
        <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
        <el-table-column width="150px"  label="IP">
          <template slot-scope="scope">
            {{ scope.row['ip'] }}
          </template>
        </el-table-column>
        <el-table-column width="150px"  label="设备">
          <template slot-scope="scope">
            {{ scope.row['deviceText'] }}
          </template>
        </el-table-column>
        <el-table-column width="180px" align="center" label="答题用时（秒）">
          <template slot-scope="scope">
            {{ scope.row['useTime'] }}
          </template>
        </el-table-column>
        <el-table-column width="180px" align="center" label="提交时间">
          <template slot-scope="scope">
            {{ scope.row['submitDateTime'] }}
          </template>
        </el-table-column>
        <el-table-column label="提交人" align="center">
          <template slot-scope="scope">
            {{ scope.row['name'] }}
          </template>
        </el-table-column>
        <el-table-column width="180px"  v-for="(asq,index) in asqNames" :key="asq" align="center">
          <template slot="header">
            <!-- <DsfMutiEllipsis :text="asq" :height="40"/> -->
            <div :title="asq" :class="{'table-head-muil-ie':isIE&&asq.length>25, 'table-head-muil':!isIE}">{{asq}}</div>
          </template>
          <template slot-scope="scope">
            <span v-if="['dsf.asqquestionimgselect','dsf.asqquestionsignature'].includes(scope.row['questionsRecordList'][index].itemsValue)">
              <el-image  style="width: 80px" v-if="scope.row['questionsRecordList'][index].imgPath" :preview-src-list="[scope.row['questionsRecordList'][index].imgPath]"  :src="scope.row['questionsRecordList'][index].imgPath" fit="scale-down"></el-image>
              <template v-else>无</template>
            </span>
            <span>
              <div :title="scope.row['questionsRecordList'][index].answer" :class="{'table-head-muil-ie':isIE&&(scope.row['questionsRecordList'][index].answer&&scope.row['questionsRecordList'][index].answer.length>25), 'table-head-muil':!isIE}">{{scope.row['questionsRecordList'][index].answer}}</div>
              <!-- {{scope.row['questionsRecordList'][index].answer}} -->
            </span>
          </template>
        </el-table-column>
     
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <div class="opeartion-btns" style="justify-content: center">
              <span @click="look(scope)" style="margin-right: 0">查看</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div :class="['ds-datagrid-pager-align_' + paginationPosition]">
        <el-pagination
          ref="pagination"
          :class="[paginationStyle]"
          :hide-on-single-page="false"
          :total="pageCount"
          :page-size="pageSize"
          :page-sizes="limits"
          :current-page.sync="currentPage"
          :layout="'slot,' + pagerLayout"
          @size-change="pageSizeChange"
        >
        </el-pagination>
      </div>
      <!-- <el-empty style="background:#FFF" description="未查询到数据"></el-empty> -->
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfAsqQuestionDataCollect',
  ctrlCaption: '问卷统计汇总',
  mixins: [$mixins.control],
  props: {
    pagerLayout: {
      type: String,
      default: 'total,prev,pager,next,jumper,sizes'
    },
    paginationStyle: {
      type: String,
      default: 'style1'
    },
    // 每页显示条数(逗号分隔)
    limits: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50, 100]
      }
    },
    // 分页位置
    paginationPosition: {
      type: String,
      default: 'flex-end'
    }
  },
  data() {
    return {
      id: '',
      tableData: [],
      pageSize: 10,
      //是否展示筛选条件
      showCondition: true,
      currentPage: 1,
      isAnonymity:'',
      pageCount: 0,
      // 默认的展示形式
      currentShowLayout: {},
      opts: [],
      //题型名字
      asqNames:[],
      conditions: {
        deviceType: '',
        respondentId:'',
        submit_datetime: '',
        keywords: ''
      }
    }
  },
  computed: {
    isIE(){
      return dsf.client.type.indexOf('IE')>-1
    }
  },
  watch: {
    currentPage() {
      this.$nextTick(() => this.loadData())
    }
  },
  created() {
    this.id = this.queryString.id
    this.isAnonymity = this.queryString.isAnonymity
    if (!this.isDesign) {
      this.init()
      this.loadData()
    }
  },
  methods: {
    init() {
      this.$http
        .get('/asq/asq-info/batch/respondent/getRespondent', { asqInfoId: this.id }, null, $$webRoot.default)
        .done(({ success, data, message }) => {
          if (success) {
            this.opts = data
          } else {
            dsf.layer.message(message, false)
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false)
        })
        .always(() => {})
    },
    look(scope) {
      let row = scope.row
      this.$openWindow({
        url: `/asq/asq-info/gotoAsqPaper?type=pc&asqInfoId=${this.id}&batchId=${row['batchId']}&recordId=${row['recordId']}`
      })
    },
     //下载Exel
    downLoadExcel() {
      const a = document.createElement('a')
      a.setAttribute('href', dsf.url.getWebPath(`/asq/asq-info/downStatisticsDetail?id=${this.id}`, $$webRoot.default))
      a.setAttribute('download', true)
      a.click()
    },
    reset() {
      this.conditions.deviceType = ''
      this.conditions.submit_datetime = ''
      this.conditions.keywords = ''
      this.conditions.respondentId = ''
      
    },
    reloadData() {
      this.currentPage = 1
      this.$nextTick(() => this.loadData())
    },
    loadData() {
      let { currentPage: pageNum, pageSize } = this
      let deviceType = this.conditions.deviceType
      let keywords = this.conditions.keywords
      let submit_datetime = this.conditions.submit_datetime
       let startDate = submit_datetime ? submit_datetime[0] : ''
      let endDate = submit_datetime ? submit_datetime[1] : ''
      let belongsValue = this.conditions.respondentId

      let defaultParams = {
        belongsValue,
        startDate,
        endDate,
        keywords,
        deviceType,
        pageNum,
        pageSize
      }
      dsf.mix(defaultParams, this.queryString)
      this.$http
        .get('/asq/asq-info/getStatisticsDetail', defaultParams, null, $$webRoot.default)
        .done(({ success, data, message }) => {
          if (success) {
            this.tableData = data.statisticAsqInfoRecordVoList
            this.asqNames = data.questionNames
          } else {
            dsf.layer.message(message, false)
            return {}
          }
          return this.$http.get('/asq/asq-info/getStatisticsDetailCount', defaultParams, null, $$webRoot.default)
        })
        .done(({ success, data }) => {
          if (success) {
            this.pageCount = data
          }
        })
        .error((err) => {
          dsf.layer.message(err?.message, false)
        })
        .always(() => {})
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize
      this.reloadData()
    }
  }
})
</script>
