<!--
 * @Descripttion: 统计面板
 * @Author: zhanghang
 * @Date: 2022-01-19 17:44:34
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-06-14 17:16:21
-->
<template>
  <div class="statistics-panle nopaddingd">
    <template>
      <div class="asq-question-name">
        <div class="asq-question-title">
          <span class="asq-question-type">【{{ asqKeyNames[questionType] }}】</span>
          {{ it.name }}
        </div>
        <div class="asq-operation-icon" v-if="questionType !== 'dsf.asqquestionhorzfill'">
          <i v-if="showWays[questionType].includes(1)" @click="status = 1" :class="{ select: status === 1 }" class="iconfont icon-tubiao-zhuzhuangtu" />
          <i v-if="showWays[questionType].includes(2)" @click="status = 2" :class="{ select: status === 2 }" class="iconfont icon-tongjifenxi1" />
          <i v-if="showWays[questionType].includes(3)" @click="status = 3" :class="{ select: status === 3 }" class="iconfont icon-zhexiantu" />
          <i v-if="showWays[questionType].includes(4)" @click="status = 4" :class="{ select: status === 4 }" class="iconfont icon-left-alignment" />
        </div>
      </div>
    </template>
    <div class="asq-statistics-question">
      <div v-if="status === 1" :order="it.order" class="asq-statistics-chart">
        <DsfPlatformBar ref="chart" :chart="barChart" />
      </div>
      <div v-if="status === 2" :order="it.order" class="asq-statistics-chart">
        <DsfPlatformPie ref="chart" :chart="pieChart" />
      </div>
      <div v-if="status === 3" :order="it.order" class="asq-statistics-chart">
        <DsfPlatformPolyline ref="chart" :chart="lineChart" />
      </div>
      <div v-if="status === 4" :order="it.order" class="asq-statistics-chart">
        <DsfPlatformBar ref="chart" :chart="barVerChart" />
      </div>
      <!-- 填空 -->
      <template v-if="questionType === 'dsf.asqquestionfill'">
        <div class="asq-statistics-table">
          <el-table
            :data="commonTable"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
            <el-table-column prop="content" label="回答详情"></el-table-column>
          </el-table>
        </div>
      </template>
      <!-- 横向填空 -->
      <template v-else-if="questionType === 'dsf.asqquestionhorzfill'">
        <div class="asq-statistics-table">
          <!-- <el-table
            :data="fills.length > 0 ? fillTable : []"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" :width="fills.length > 0 ? 70 : '843'"> </el-table-column>
            <el-table-column v-for="(item, index) in fills" :key="index" :label="'填空' + (index + 1)">      bn     
              <template slot-scope="scope">
                {{ scope.row.fills[index] }}
              </template>
            </el-table-column>
          </el-table> -->
          <el-table
            :data="fills"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
            <el-table-column   v-for="(item,_index) in computeOptions" :key="_index" :label="'填空' + (_index + 1)">
              <template slot-scope="props">
                {{item.fills[props.$index]}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <!-- 排序题 -->
      <template v-else-if="questionType === 'dsf.asqquestionorder'">
        <div class="asq-statistics-table">
          <el-table
            :data="computeOptions"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column
              label="选项"
              align="center"
            ><template
              slot-scope="scope"
            ><div class="ellipsis" :title="scope.row.text">选项{{ scope.$index + 1 }} ： {{ scope.row.text }}</div></template
            >
            </el-table-column>
            <el-table-column v-for="(item, index) in it.options" :key="index" :label="'第' + (index + 1) + '位'">
              <template slot-scope="scope"> {{ getRank(scope, index) }}% </template>
            </el-table-column>
            <el-table-column prop="avg" label="平均排名"></el-table-column>
          </el-table>
        </div>
      </template>
      <!-- 矩阵单选、矩阵多选 、矩阵打分-->
      <template v-else-if="['dsf.asqquestionmatrixselect', 'dsf.asqquestionmatrixmutilselect', 'dsf.asqquestionmatrixscore'].includes(questionType)">
        <div class="asq-statistics-table">
          <el-table
            :data="computeOptions"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column label="选项" align="center">
              <template slot-scope="scope">
                <div class="ellipsis" :title="scope.row.children[0].text">
                  {{ scope.row.children[0].text }}
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="(item, index) in matrixColumns" :key="index" :label="item.text">
              <template slot-scope="scope">
                <template v-if="questionType === 'dsf.asqquestionmatrixscore'">
                  {{
                    index === matrixColumns.length - 1
                      ? computeOptions[scope.$index].children[index + 1].submitNum
                      : computeOptions[scope.$index].children[index + 1].avg + '分'
                  }}
                </template>
                <template v-else>
                  {{
                    index === matrixColumns.length - 1
                      ? computeOptions[scope.$index].children[index + 1].submitNum
                      : computeOptions[scope.$index].children[index + 1].proportion + '%'
                  }}
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <!-- 矩阵填空-->
      <template v-else-if="questionType === 'dsf.asqquestionmatrixinput'">
        <div class="asq-statistics-table">
          <el-table
            :data="pageOptions"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column label="选项" align="center">
              <template slot-scope="scope">
                <div class="ellipsis" :title="scope.row.children[0].text">
                  {{ scope.row.children[0].text }}
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="(item, index) in matrixColumns" :key="index" :label="item.text">
              <template slot-scope="scope">
                {{ pageOptions[scope.$index].children[index + 1].content }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          background
          :style="{'text-align':'right'}"
          :current-page="curPage"
          @current-change="(e) => (this.curPage = e)"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
      </template>
      <!-- 自增表格-->
      <template v-else-if="questionType === 'dsf.asqquestiontable'">
        <div class="asq-statistics-table">
          <el-table
            :data="pageOptionsTab"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column v-for="(item, index) in matrixColumnsTab" :key="index" :label="item.text">
              <template slot-scope="scope">
                {{ pageOptionsTab[scope.$index].children[index].content }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          background
          :style="{'text-align':'right'}"
          :current-page="curPage"
          @current-change="(e) => (this.curPage = e)"
          :page-size="1"
          layout="prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
      </template>
      <!-- 打分 -->
      <template v-else-if="questionType === 'dsf.asqquestionscore' || questionType === 'dsf.asqquestionproportion'">
        <div class="asq-statistics-table">
          <el-table
            :data="computeOptions"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            :fit="true"
            style="width: 100%"
          >
            <el-table-column
              label="选项"
              align="center"
            ><template
              slot-scope="scope"
            ><div class="ellipsis">选项{{ scope.$index + 1 }} ： {{ scope.row.text }}</div></template
            >
            </el-table-column>
            <el-table-column :label="questionType === 'dsf.asqquestionscore' ? '最高分' : '最高占比'">
              <template slot-scope="scope">{{ scope.row.max || 0 }}{{ questionType === 'dsf.asqquestionscore' ? '分' : '%' }} </template>
            </el-table-column>
            <el-table-column prop="min" :label="questionType === 'dsf.asqquestionscore' ? '最低分' : '最低占比'">
              <template slot-scope="scope"> {{ scope.row.min || 0 }}{{ questionType === 'dsf.asqquestionscore' ? '分' : '%' }} </template>
            </el-table-column>
            <el-table-column prop="avg" :label="questionType === 'dsf.asqquestionscore' ? '平均分数' : '平均占比'" width="120">
              <template slot-scope="scope"> {{ scope.row.avg || 0 }}{{ questionType === 'dsf.asqquestionscore' ? '分' : '%' }} </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <!-- 电子签名 -->
      <template v-else-if="questionType === 'dsf.asqquestionsignature'">
        <div class="asq-statistics-table">
          <el-table
            :data="commonTable"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            header-cell-class-name="head-bg"
            border
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
            <el-table-column label="签名">
              <template slot-scope="scope">
                <el-image
                  style="width: 180px"
                  :ref="`preImage${scope.$index}`"
                  :preview-src-list="[getSignature(scope)]"
                  v-if="scope.row.files"
                  :src="getSignature(scope)"
                  fit="scale-down"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="opeartion-btns">
                  <span @click="preView($event, scope)">预览</span><span><a download :href="getSignature(scope)">下载</a></span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template v-else>
        <div class="asq-statistics-table">
          <el-table
            :data="commonTable"
            :highlight-current-row="false"
            :cell-style="{ color: '#262F35' }"
            :header-cell-style="{ background: ' #f5f7fa' }"
            border
            style="width: 100%"
          >
            <el-table-column label="选项">
              <template slot-scope="scope">
                <template v-if="scope.row.img">
                  选项{{ scope.$index + 1 }} ：
                  <el-image style="width: 80px" :preview-src-list="[scope.row.img]" v-if="scope.row.img" :src="scope.row.img" fit="scale-down"></el-image>
                  {{ scope.row.text }}
                </template>
                <template v-else>
                  <div class="ellipsis" :title="scope.row.text">选项{{ scope.$index + 1 }} ： {{ scope.row.text }}</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="rate" label="百分比" width="270"> </el-table-column>
            <el-table-column prop="chooseNum" label="响应数" width="110"></el-table-column>
          </el-table>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import bar from './chartOptions/bar.js'
import pie from './chartOptions/pie.js'
import line from './chartOptions/line.js'
import barVer from './chartOptions/barver.js'

/*
  多选题	dsf.asqquestioncheckbox
  单选题	dsf.asqquestionradio
  图片选择	dsf.asqquestionimgselect
  下拉题	dsf.asqquestionselect
  判断题	dsf.asqquestionjudge
  级联题	dsf.asqquestioncascader
  打分题	dsf.asqquestionscore
  填空题	dsf.asqquestionfill
  横向填空	dsf.asqquestionhorzfill
  排序题	dsf.asqquestionorder
  比重题	dsf.asqquestionproportion
  电子签名	dsf.asqquestionsignature

  矩阵单选 dsf.asqquestionmatrixselect
  矩阵多选 dsf.asqquestionmatrixmutilselect
  矩阵填空 dsf.asqquestionmatrixinput
  矩阵打分 dsf.asqquestionmatrixscore
  自增表格 dsf.asqquestiontable
  status 0没图表 1 柱状图 2 饼状图 3 折现图 4条形图
*/
//题型名称对应表
const asqKeyNames = {
  'dsf.asqquestionradio': '单选题',
  'dsf.asqquestionjudge': '判断题',
  'dsf.asqquestioncheckbox': '多选题',
  'dsf.asqquestionimgselect': '图片选择',
  'dsf.asqquestionselect': '下拉题',
  'dsf.asqquestioncascader': '级联题',
  'dsf.asqquestionscore': '打分题',
  'dsf.asqquestionfill': '填空题',
  'dsf.asqquestionhorzfill': '横向填空',
  'dsf.asqquestionorder': '排序题',
  'dsf.asqquestionproportion': '比重题',
  'dsf.asqquestionsignature': '电子签名',

  'dsf.asqquestionmatrixselect': '矩阵单选',
  'dsf.asqquestionmatrixmutilselect': '矩阵多选',
  'dsf.asqquestionmatrixinput': '矩阵填空',
  'dsf.asqquestiontable': '自增表格',
  'dsf.asqquestionmatrixscore': '矩阵打分'
}
//题型默认展示
const defaultMap = {
  'dsf.asqquestionradio': 3,
  'dsf.asqquestionjudge': 3,
  'dsf.asqquestioncheckbox': 3,
  'dsf.asqquestionimgselect': 2,
  'dsf.asqquestionselect': 2,
  'dsf.asqquestioncascader': 2,
  'dsf.asqquestionscore': 1,
  'dsf.asqquestionfill': 0,
  'dsf.asqquestionhorzfill': 0,
  'dsf.asqquestionorder': 1,
  'dsf.asqquestionproportion': 1,
  'dsf.asqquestionsignature': 0,

  'dsf.asqquestionmatrixselect': 3,
  'dsf.asqquestionmatrixmutilselect': 3,
  'dsf.asqquestionmatrixinput': 0,
  'dsf.asqquestionmatrixscore': 3,
  'dsf.asqquestiontable': 0
}
//展示方式
const showWays = {
  'dsf.asqquestionradio': [1, 2, 3, 4],
  'dsf.asqquestionjudge': [1, 2, 3, 4],
  'dsf.asqquestioncheckbox': [1, 3, 4],
  'dsf.asqquestionimgselect': [1, 2, 3, 4],
  'dsf.asqquestionselect': [1, 2, 3, 4],
  'dsf.asqquestioncascader': [1, 2, 4],
  'dsf.asqquestionscore': [1, 3, 4],
  'dsf.asqquestionfill': [],
  'dsf.asqquestionhorzfill': [],
  'dsf.asqquestionorder': [1, 3, 4],
  'dsf.asqquestionproportion': [1, 3, 4],
  'dsf.asqquestionsignature': [],

  'dsf.asqquestionmatrixselect': [1, 3, 4],
  'dsf.asqquestionmatrixmutilselect': [1, 3, 4],
  'dsf.asqquestionmatrixinput': [],
  'dsf.asqquestionmatrixscore': [1, 3, 4],
  'dsf.asqquestiontable': []
}
let commonChartMatrix = function () {
  let xdata = []
  let series = []
  let map = new Map()

  this.it.options.forEach((it, index) => {
    let name = it.children[0].text
    xdata.push(name)
    for (let i = 1; i < it.children.length - 1; i++) {
      let cur = it.children[i]
      let text = cur.text
      let value = this.questionType === 'dsf.asqquestionmatrixscore' ? cur.avg : cur.proportion
      if (map.has(text)) {
        let arr = map.get(text)
        map.set(text, [...arr, value])
      } else {
        map.set(text, [value])
      }
    }
  })
  for (let [name, value] of map) {
    series.push({ name, value })
  }
  return { series, xdata }
}

export default dsf.component({
  name: 'DsfAsqQuestionStatisticsPanle',
  ctrlCaption: '题型统计面板',
  inject: ['active'],
  props: {
    questionType: {
      type: String,
      default: 'dsf.asqquestionradio'
    },
    it: {
      type: Object,
      default() {
        return {}
      }
    },
    showType: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      // 默认状态
      status: defaultMap[this.questionType],
      //展示方式
      showWays,
      asqKeyNames,
      pageSize: 5,
      curPage: 1
    }
  },
  computed: {
    // 普通统计
    commonTable() {
      let total = this.it.options.reduce((t, c) => t + c.chooseNum, 0)
      return this.it.options.map((item) => {
        return {
          ...item,
          rate: total ? ((item.chooseNum * 100) / total).toFixed(2) + '%' : '0%'
        }
      })
    },
    //options
    computeOptions() {
      return this.it.options
    },
    //分页总条数
    pageTotal() {
      return this.computeOptions.length
    },
    //分页填空
    pageOptions() {
      let cur = this.curPage - 1
      let pageSize = this.pageSize
     
      return this.computeOptions.slice(cur * pageSize, (cur + 1) * pageSize)
    },
    pageOptionsTab(){
      let cur = this.curPage-1
      return this.computeOptions[cur].children
    },
    fills() {
      return this.computeOptions[0]?.fills ? this.computeOptions[0]?.fills : []
    },
    //矩阵列名字
    matrixColumns() {
      let computeOptions = this.computeOptions
      return computeOptions[0].children ? computeOptions[0].children.slice(1) : []
    },
    //自增表格矩阵列名
    matrixColumnsTab(){
      let computeOptions = this.computeOptions[0].children
      return computeOptions[0].children ? computeOptions[0].children: []
    },
    barChart() {
      if (['dsf.asqquestionproportion', 'dsf.asqquestionscore'].includes(this.questionType)) {
        let xdata = []
        let max = []
        let min = []
        let avg = []
        this.it.options.forEach((it, index) => {
          xdata.push('选项' + (index + 1))
          max.push(it.max)
          min.push(it.min)
          avg.push(it.avg)
        })
        let series = []
        if (this.questionType === 'dsf.asqquestionproportion') {
          series = [
            { name: '最高占比', value: max },
            { name: '最低占比', value: min },
            { name: '平均占比', value: avg }
          ]
        } else if (this.questionType === 'dsf.asqquestionscore') {
          series = [
            { name: '最高分', value: max },
            { name: '最低分', value: min },
            { name: '平均分', value: avg }
          ]
        }

        return bar(series, xdata, true, this.questionType)
        // 矩阵单选 多选 打分
      } else if (['dsf.asqquestionmatrixselect', 'dsf.asqquestionmatrixmutilselect', 'dsf.asqquestionmatrixscore'].includes(this.questionType)) {
        let { series, xdata } = commonChartMatrix.call(this)
        return bar(series, xdata, true, this.questionType)
      } else {
        let xdata = this.it.options.map((it, index) => '选项' + (index + 1))
        let total = this.it.options.reduce((t, c) => t + c.chooseNum, 0)
        let value = []
        if (this.questionType === 'dsf.asqquestionorder') {
          value = this.it.options.map((it) => it.avg)
        } else {
          value = this.it.options.map((it) => (total ? ((it.chooseNum * 100) / total).toFixed(2) : 0))
        }
        return bar(value, xdata, false, this.questionType)
      }
    },
    lineChart() {
      if (['dsf.asqquestionproportion', 'dsf.asqquestionscore'].includes(this.questionType)) {
        let xdata = []
        let max = []
        let min = []
        let avg = []
        this.it.options.forEach((it, index) => {
          xdata.push('选项' + (index + 1))
          max.push(it.max)
          min.push(it.min)
          avg.push(it.avg)
        })
        let series = []
        if (this.questionType === 'dsf.asqquestionproportion') {
          series = [
            { name: '最高占比', value: max },
            { name: '最低占比', value: min },
            { name: '平均占比', value: avg }
          ]
        } else if (this.questionType === 'dsf.asqquestionscore') {
          series = [
            { name: '最高分', value: max },
            { name: '最低分', value: min },
            { name: '平均分', value: avg }
          ]
        }
        return line(series, xdata, true, this.questionType)
        // 矩阵单选 多选 打分
      } else if (['dsf.asqquestionmatrixselect', 'dsf.asqquestionmatrixmutilselect', 'dsf.asqquestionmatrixscore'].includes(this.questionType)) {
        let { series, xdata } = commonChartMatrix.call(this)
        return line(series, xdata, true, this.questionType)
      } else {
        let xdata = this.it.options.map((it, index) => '选项' + (index + 1))
        let total = this.it.options.reduce((t, c) => t + c.chooseNum, 0)
        let value = []
        if (this.questionType === 'dsf.asqquestionorder') {
          value = this.it.options.map((it) => it.avg)
        } else {
          value = this.it.options.map((it) => (total ? ((it.chooseNum * 100) / total).toFixed(2) : 0))
        }
        return line(value, xdata, false, this.questionType)
      }
    },
    pieChart() {
      let total = this.it.options.reduce((t, c) => t + c.chooseNum, 0)
      let data = this.it.options.map((it, index) => {
        return { name: '选项' + (index + 1), value: total ? ((it.chooseNum * 100) / total).toFixed(2) : 0 }
      })
      return pie(
        data,
        this.it.options.map((it, index) => '选项' + (index + 1))
      )
    },
    barVerChart() {
      if (['dsf.asqquestionproportion', 'dsf.asqquestionscore'].includes(this.questionType)) {
        let xdata = []
        let max = []
        let min = []
        let avg = []
        this.it.options.forEach((it, index) => {
          xdata.push('选项' + (index + 1))
          max.push(it.max)
          min.push(it.min)
          avg.push(it.avg)
        })
        let series = []
        if (this.questionType === 'dsf.asqquestionproportion') {
          series = [
            { name: '最高占比', value: max },
            { name: '最低占比', value: min },
            { name: '平均占比', value: avg }
          ]
        } else if (this.questionType === 'dsf.asqquestionscore') {
          series = [
            { name: '最高分', value: max },
            { name: '最低分', value: min },
            { name: '平均分', value: avg }
          ]
        }
        return barVer(series, xdata, true, this.questionType)
        // 矩阵单选 多选 打分
      } else if (['dsf.asqquestionmatrixselect', 'dsf.asqquestionmatrixmutilselect', 'dsf.asqquestionmatrixscore'].includes(this.questionType)) {
        let { series, xdata } = commonChartMatrix.call(this)
        return barVer(series, xdata, true, this.questionType)
      } else {
        let xdata = this.it.options.map((it, index) => '选项' + (index + 1))
        let total = this.it.options.reduce((t, c) => t + c.chooseNum, 0)
        let value = []
        if (this.questionType === 'dsf.asqquestionorder') {
          value = this.it.options.map((it) => it.avg)
        } else {
          value = this.it.options.map((it) => (total ? ((it.chooseNum * 100) / total).toFixed(2) : 0))
        }

        return barVer(value, xdata, false, this.questionType)
      }
    }
  },
  watch: {
    active: {
      handler(v) {
        if (v.value === '1') {
          this.$refs?.chart?.$resize()
        }
      },
      deep: true
    },
    //展示方式改变
    showType: {
      handler(v) {
        //默认
        if (v === -1) {
          this.status = defaultMap[this.questionType]
        } else {
          if (this.showWays[this.questionType].includes(v)) {
            this.status = v
          }
        }
      },
      deep: true
    }
  },

  methods: {
    getSignature(scope) {
      return dsf.url.getWebPath(JSON.parse(scope.row.files)[0].absolutePath.replace(/^\/\//g, '/'))
    },
    //预览功能
    preView($event, scope) {
      let preImage = this.$refs[`preImage${scope.$index}`]
      preImage.showViewer = true
    },
    getRank(scope, index) {
      let arr = new Array(this.it.options.length).fill(0)
      for (let key in scope.row.orderMap) {
        arr[key - 1] = scope.row.orderMap[key]
      }
      return arr[index]
    }
  }
})
</script>
