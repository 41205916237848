let router = [
  // {
  //   path: "/asqcomplete",
  //   name: "questionComplete",
  //   meta: {
  //     title: "完成问卷"
  //   },
  //   component: DsfQuestionDesigner,
  //   props: route => {
  //     return {
  //       business: route.query.B,
  //       module: route.query.M,
  //       pageName: route.query.pname,
  //       pageTitle: route.query.title,
  //       mobile: false,
  //       isPrototype: route.query._prototype == 1 ? true : false,
  //       tpl: route.query.tpl == 1 ? true : false,
  //       tplCode: route.query.tplcode,
  //       templateType: route.query.templateType,
  //       layoutType: route.params.pageType
  //     };
  //   }
  // },
];

export default router;