/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-04-21 15:20:44
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-07-14 10:29:25
 */
export default {
  data() {
    return {
      form: {},
      rules: [
        {
          validator: (rule, value, callback) => {
            let [index, uuid] = rule.field.split(',')
            let _value = this.rowsData[index].model[uuid].attach
            let name = this.rowsData[index].model[uuid].value
            let r = this.rowsData[index].setting[uuid]
            if (r.required && name) {
              let isTrue = this.validator.required(_value)
              isTrue ? callback() : callback(this.MESSAGE_TYPE['required'])
            } else {
              callback()
            }
          }
        },
        {
          validator: (rule, value, callback) => {
            let [index, uuid] = rule.field.split(',')
            let _value = this.rowsData[index].model[uuid].attach
            let name = this.rowsData[index].model[uuid].value
            let r = this.rowsData[index].setting[uuid]
            let type = r.reg
            if (this.validator[type] && name) {
              let isTrue = this.validator[type](_value)
              isTrue ? callback() : callback(this.MESSAGE_TYPE[type])
            } else {
              callback()
            }
          }
        }
      ]
    }
  },
  methods: {
    // 自定义表单校验
    async customVaildate() {
      return new Promise((resolve) => {
        if (this.$refs?.form?.validate) {
          this.$refs?.form
            .validate()
            .then((res) => resolve(res))
            .catch((err) => resolve(err))
        } else {
          resolve(true)
        }
      })
    },
  }
}
