/*
 * @Descripttion: 矩阵验证
 * @Author: zhanghang
 * @Date: 2022-03-08 15:01:48
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-20 11:02:58
 */
const MESSAGE_TYPE = {
  required: '该项不能为空',
  '3':'该项必须是数字',
  '4':'该项必须为字母',
  '5':'该项必须为中文',
  '6':'该项格式不正确',
  '7':'该项格式不正确',
  '8':'该项格式不正确',
  '9':'该项格式不正确',
}

//检验函数
const validator = {
  required(value) {
    if (!value || value?.trim() === '') {
      return false
    } else {
      return true
    }
  },
  3: function (value) {
    return !(value && !/^\d+$/.test(value) )
  },
  4: function (value) {
    return !(value &&!/^[a-zA-Z]+$/.test(value))
  },
  5: function (value) {
    return !(value && !dsf.validate('isChinese', value) )
  },
  6: function (value) {
    return !(value && !dsf.validate('isEmail', value))
  },
  7: function (value) {
    return !(value&& !dsf.validate('isMobile', value)) 
  },
  8: function (value) {
    return !(value && !/^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(value) )
  },
  9: function (value) {
    return !(value && !dsf.validate('isIDCardFull', value) )
  }
}

const types = [
  {
    text: '数字',
    value: '3'
  },
  {
    text: '字母',
    value: '4'
  },
  {
    text: '中文',
    value: '5'
  },
  {
    text: 'Email',
    value: '6'
  },
  {
    text: '手机号',
    value: '7'
  },
  {
    text: '网址',
    value: '8'
  },
  {
    text: '身份证',
    value: '9'
  }
]
export {
  validator,
  types,
  MESSAGE_TYPE,
}