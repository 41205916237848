/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-01-18 17:18:55
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-05-09 18:04:09
 */
import color from './color'

function created(value, xdata, multitude,type) {
  let series = []
  if (multitude) {
    value.forEach(it => {
      series.push({
          "type": "bar",
          "yAxisIndex": 0,
          "xAxisIndex": 0,
          "name":it.name,
          "label": {
            "show": true,
            "position": "top",
            "formatter": function (item) {
              return type==='dsf.asqquestionscore'||type==='dsf.asqquestionmatrixscore'? item.data + '分':item.data + '%'
            },
            "color": "#333",
          },
          "barGap": "30%",
          "barCategoryGap": "10%",
          "barWidth": "30",
          "showBackground": false,
          "data": it.value,
      })
    });
  } else {
    series = [
      {
        "type": "bar",
        "yAxisIndex": 0,
        "xAxisIndex": 0,
        "label": {
          "show": true,
          "position": "top",
          "formatter": function (item) {
            return item.data + (type==='dsf.asqquestionorder'?'':'%')
          },
          "color": "#333",

        },
        "barGap": "30%",
        "barCategoryGap": "10%",
        "barWidth": "30",
        "showBackground": false,
        "data": value,
      }
    ]
  }

  return {
    "series": series,
    color,
    "title": {
      "show": false,
    },
    "legend": {
      "show": multitude?true:false,
      "left": "auto",
			"top": "auto",
			"right": "30",
			"bottom": "50",
			"orient": "vertical",
			"icon": "circle",
    },
    "grid": {
      "width": "60%",
      "left": "center",
      "top": 30,
      "right": 10,
      "bottom": 30,
      "containLabel": true,
      "backgroundColor": "transparent",
      "borderColor": "#ccc",
      "borderWidth": 1,
      "show": false
    },
    "xAxis": [
      {
        "type": "category",
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#F0F2F5"
          }
        },
        "axisTick": {
          "show": false,
          "alignWithLabel": true
        },
        "axisLabel": {
          "show": true,
          "fontSize": 12,
          "rotate": 0,
          interval:0,
          "color": "#333"
        },
        "splitLine": {
          "show": false
        },
        "name": "",
        "nameLocation": "center",
        "nameTextStyle": {
          "color": "#333",
          "fontSize": 12
        },
        "nameGap": 30,
        "boundaryGap": true,
        "position": "bottom",
        "interval": 0,
        "data": xdata
      }
    ],
    "yAxis": [
      {
        "type": "value",
        "axisLine": {
          "show": false,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisTick": {
          "show": false
        },
        "axisLabel": {
          "show": true,
          "fontSize": 12,
          "rotate": 0,
          "color": "#999999"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": "#F0F2F5"
          }
        },
        "name": "",
        "nameLocation": "end",
        "nameTextStyle": {
          "color": "#333",
          "fontSize": 12
        },
        "nameGap": 18,
        "position": "left",
        "max": (multitude||type==='dsf.asqquestionorder') ? 'dataMax' : 100,
        "min": 0
      }
    ]
  }
}
export default created