<!--
 * @Descripttion: 矩阵打分
 * @Author: zhanghang
 * @Date: 2022-02-28 14:03:38
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-09 18:18:04
-->
<template>
  <DsfAsqQuestionBody class="ds-question-table" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <el-table ref="table" :data="rowsData" border :header-cell-style="{ background: '#f5f7fa', color: '#606266' }">
        <!-- 占位 -->
        <el-table-column align="center" label="" width="180">
          <template v-slot="scope">
            {{ scope.row.text }}
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="center" v-for="(it, index) in columns" :label="it.text" :key="index">
          <template v-slot="scope">
            <el-rate
              style="height: auto"
              :value="Number(scope.row.model[it.uuid].value)"
              :max="max"
              :disabled="readOnly || disabled" 
              :colors="colors"
              :void-icon-class="voidClass"
              :disabled-void-icon-class="voidClass"
              @change="changeScore($event, scope, it)"
            >
            </el-rate>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
/* value 二位数组数据结构
 *  {行id-列id:{value:'',attach:''}}
 */
export default dsf.component({
  name: 'DsfAsqQuestionMatrixScore',
  ctrlCaption: '矩阵打分',
  mixins: [$mixins.asqQuestionMatrix],
  props: {
    stem: {
      type: String,
      default: '矩阵打分'
    },
    max: {
      type: [String, Number],
      default: 10
    },
    controlMode: {
      type: String,
      default: 'maxtrixScore'
    }
  },
  data() {
    return {
      colors: [this.$root.currentTheme.normal, this.$root.currentTheme.normal, this.$root.currentTheme.normal],
      voidClass: ' iconfont icon-icon_star_line1'
    }
  },
  methods: {
    // change 事件
    changeScore(score, scope, it) {
      scope.row.model[it.uuid].value = score
    }
  }
})
</script>
