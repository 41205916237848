<!--
 * @Descripttion: 矩阵选择
 * @Author: zhanghang
 * @Date: 2022-03-03 09:17:13
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-21 16:09:57
-->
<template>
  <DsfAsqQuestionBody class="ds-question-table" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <el-form ref="form" :model="form" label-width="0">
        <el-table ref="table" :data="rowsData" border :header-cell-style="{ background: '#f5f7fa', color: '#606266' }">
          <!-- 占位 -->
          <el-table-column label="" align="center" width="180">
            <template v-slot="scope">
              {{ scope.row.text }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" align="center" v-for="(it, index) in columns" :label="it.text" :key="index">
            <template v-slot="scope">
              <!-- 多选 -->
              <div style="display: flex; justify-content: center; align-items: center">
                <el-checkbox
                  :disabled="readOnly || disabled"
                  style="margin-right: 0"
                  :value="Boolean(scope.row.model[it.uuid].value)"
                  @change="changeCheckbox(scope, it)"
                >
                  <span></span>
                </el-checkbox>

                <el-form-item style="position: relative" :prop="`${scope.$index},${it.uuid}`" :rules="rules">
                  <el-input
                    size="small"
                    :disabled="readOnly || disabled"
                    v-if="scope.row.model[it.uuid].value && scope.row.setting[it.uuid].isinput"
                    :maxlength="scope.row.setting[it.uuid].limit"
                    v-model="scope.row.model[it.uuid].attach"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
/* value 数据结构 [value]选中项 [attach]选项输入框内容
 * {行id-列id:{value:'',attach:''}}
 */
import selectMixins from './matrixSelect.js'
export default dsf.component({
  name: 'DsfAsqQuestionMatrixMutilSelect',
  ctrlCaption: '矩阵多选',
  mixins: [$mixins.asqQuestionMatrix,selectMixins],
  props: {
    stem: {
      type: String,
      default: '矩阵多选'
    },
    max: {
      type: [String, Number],
      default: 10
    },
    controlMode: {
      type: String,
      default: 'maxtrixMutilSelect'
    }
  },
  methods: {
    //change 复选框
    changeCheckbox(scope, it) {
      if (scope.row.model[it.uuid].value) {
        scope.row.model[it.uuid].value = ''
        scope.row.model[it.uuid].attach = ''
      } else {
        scope.row.model[it.uuid].value = true
      }
      this.$refs?.form.validate()
    }
  }
})
</script>
