/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-07 11:22:37
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-18 15:53:43
 */
//注入按钮
import "./sysButton";

import * as Ctrls from './components';

const components = Object.values(Ctrls);

function install(Vue) {
  components.forEach((it) => {
    Vue.component(it.name, it);
    if (!window.$components['asqQuestion']) {
      window.$components['asqQuestion'] = {}
    }
    window.$components['asqQuestion'][it.name] = it;
  })
}

export default install;

export {
  install
};
