<template>
  <div class="ds-control ds-page" :page-type="pageType" :class="getCss" :style="pageStyle">
    <!--页头-->
    <!-- <div class="ds-page-head">
      <div ref="head" class="head-inner" :slot-name="slots[1].name">
        <slot :name="slots[1].name"></slot>
      </div>
    </div> -->
    <div :ref="'content'" class="ds-page-content" :style="getBackgroundStyle" >
      <div class="ds-page-content-wrap" :style="pageContentStyle">
        <!--设计器标题及欢迎语编辑-->
        <div class="ds-question-page-welcome">
          <template v-if="isDesign">
            <DsfQuestionDesignerEditor
              class="welcome-text"
              :value.sync="welcomeText"
              :align="textAlign"
              :placeholder="'调查问卷'"
              empty-use-placeholder
            ></DsfQuestionDesignerEditor>
            <div style="color: red; text-align: center" v-if="!vaildName">限制字数在0-{{ nameLimit }}内</div>
            <DsfQuestionDesignerEditor class="welcome-sub-text" :value.sync="welcomeSubText" :placeholder="'欢迎使用'"></DsfQuestionDesignerEditor>
          </template>
          <template v-else>
            <div :style="{ 'text-align': textAlign }" class="welcome-text" v-html="welcomeText"></div>
            <div class="welcome-sub-text" v-html="welcomeSubText"></div>
            <div class="welcome-split"></div>
          </template>
        </div>
        <!--问卷主体-->
        <div class="ds-page-center" :slot-name="slots[0].name" :style="contentStyle">
          <slot :name="slots[0].name">
            <!--无题目时显示-->
            <span class="empty-title" v-if="isDesign && slots[0].controls.length <= 0">将题型拖到此区域</span>
          </slot>
          <!--问卷保存按钮-->
          <!-- && $route.query.isview != '1' -->
          <div class="ds-question-buttons" v-if="!isDesign">
            <dsf-asq-question-buttons :isPreView="isPreView"></dsf-asq-question-buttons>
          </div>
        </div>
        <!--设计器结束语编辑-->
        <div class="designer-question-import" v-if="isDesign">
          <a href="javascript:void(0)" @click="importHandler()">导入题目</a>
        </div>
        <!--设计器结束语编辑-->
        <div class="ds-question-page-complete" v-if="isDesign">
          <DsfQuestionDesignerEditor class="complete-text" align="center" :value.sync="completeText"></DsfQuestionDesignerEditor>
        </div>
      </div>
    </div>
    <!--页脚-->
    <!-- <div v-if="hasFooter" class="ds-page-foot" :style="{'background-color':footerBackground}">
      <div ref="foot" class="foot-inner" :slot-name="slots[2].name">
        <slot :name="slots[2].name"></slot>
      </div>
    </div> -->
    <div style="display: none" v-html="customCss"></div>
    <dsf-to-designer ref="eleDesgin" v-show="!isDesign" v-if="gotoIcon && !isPreView" :title="title" :goto-preview="gotoPreview"></dsf-to-designer>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfAsqQuestionPage',
  ctrlCaption: '问卷页面',
  mixins: [$components.platform.DsfPage],
  props: {
    needLogin: {
      type: Boolean,
      default: false
    },
    welcomeText: {
      type: String,
      default: '调查问卷'
    },
    welcomeSubText: {
      type: String,
      default: '欢迎使用'
    },
    completeText: {
      type: String,
      default: '您已完成本次问卷，感谢您的帮助与支持'
    },
    topBottom: {
      type: String,
      default: 'split-big'
    },
    pageType: {
      type: String,
      default: 'question'
    },
    showQuestionIndex: {
      type: Boolean,
      default: true
    },
    nameLimit: {
      type: [String, Number],
      default: '80'
    },
    textAlign: {
      type: String,
      default: 'center'
    }
  },
  data() {
    return {
      isPreView: false
    }
  },
  created() {
    //是否是预览
    if (this.queryString.isPreView || this.$parent.isPreView) {
      this.isPreView = true
    }
    if (this.isDesign && this.queryString.title) {
      this.welcomeText = this.queryString.title
    }
  },
  mounted() {
    //防止复制过来的题目带有html标签
    $('.ds-page').on('paste', 'div[contenteditable]', (e) => {
      e.stopPropagation()
      e.preventDefault()
      let text = '',
        event = e.originalEvent || e
      if (event.clipboardData && event.clipboardData.getData) {
        text = event.clipboardData.getData('text/plain')
      } else if (window.clipboardData && window.clipboardData.getData) {
        text = window.clipboardData.getData('Text')
      }
      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text)
      } else {
        document.execCommand('paste', false, text)
      }
    })
  },
  computed: {
    vaildName() {
      return this.welcomeText.length < this.nameLimit && this.welcomeText.length > 0
    },
    pageStyle() {
      return {}
    },
    pageContentStyle() {
      let style = {
        margin: '10px auto'
      }
      if (this.isDesign) {
        style.margin = 'auto'
      }
      if (this.pageMaxWidth) {
        style['max-width'] = this.unitSize(this.pageMaxWidth)
      }
      // if (dsf.client.type.startsWith('IE')) {
      //   style['border'] = '1px solid blue'
      // }
      return style
    },
    getCss() {
      return [this.isDesign ? 'is-designer' : '', ...this.$getClass()]
    },
    contentStyle() {
      return {}
    }
  },
  methods: {
    gotoPreview() {
      let isDrag = this.$refs?.eleDesgin?.$el?.getAttribute?.('data-flag')
      if (isDrag === 'true') {
        let previewUrl = dsf.url.getWebPath(
          `~/designer.html#/question?B=${this.context.B}&M=${this.context.M}&pname=${this.name}&title=${this.title}&templateType=1`
        )
        window.open(previewUrl, '_blank')
      }
    },
    blurHandler() {},
    focusHandler(evt, style) {
      // this.welcomeFocus=false;
      // this.welcomeSubFocus=false;
    },

    importHandler() {
      dsf.layer.message('功能尚在建设中', false)
    }
  }
})
</script>
