<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-16 16:27:05
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-26 18:16:55
-->
<template>
  <DsfAsqQuestionBody class="ds-question-radio" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="ds-question-options" :class="['layout--' + optionsLayout]">
        <template v-if="hasOther">
          <el-Radio
            v-model="selectValue"
            v-for="item in $items.slice(0, $items.length - 1)"
            :key="item.value"
            :class="['el-col', 'el-col-' + 24 / optionsLayout]"
            :label="item.value"
            :disabled="readOnly || disabled"
          >
            {{ item.text }}
          </el-Radio>
          <div :class="['el-col', 'el-col-' + 24 / optionsLayout]" style="display: flex; align-items: center">
            <el-Radio v-model="selectValue" style="margin-bottom: 0" :key="getLastOption.value" :label="getLastOption.value" :disabled="readOnly || disabled">
              {{ getLastOption.text }}
            </el-Radio>
            <!-- 选中其他时候才会出现 -->
            <div style="flex: 1" v-if="selectValue === getLastOption.value">
              <el-input size="small" v-model="getLastOption.attach" @change="getLastOption=getLastOption" placeholder="其他备注"></el-input>
            </div>
          </div>
        </template>
        <template v-else>
          <el-radio-group v-model="selectValue" class="el-row el-row--flex" :disabled="readOnly || disabled">
            <el-Radio
              v-for="item in $items"
              :key="item.value"
              :class="['el-col', 'el-col-' + 24 / optionsLayout]"
              :label="item.value"
              :disabled="item.disabled"
            >
              {{ item.text }}
            </el-Radio>
          </el-radio-group>
        </template>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>

<script>
export default dsf.component({
  name: 'DsfAsqQuestionRadio',
  ctrlCaption: '单选题',
  mixins: [$mixins.asqQuestionItemsMixins],
  data() {
    return {
      multiple: false
    }
  }
})
</script>
