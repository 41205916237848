<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-16 16:27:05
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-27 10:20:39
-->
<template>
  <DsfAsqQuestionBody class="ds-question-checkbox" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="ds-question-options" :class="['layout--' + optionsLayout]">
        <template v-if="hasOther">
          <el-checkbox
            v-model="selectValue"
            v-for="item in $items.slice(0, $items.length - 1)"
            :key="item.value"
            :class="['el-col', 'el-col-' + 24 / optionsLayout]"
            :label="item.value"
            :disabled="readOnly || disabled"
          >
            {{ item.text }}
          </el-checkbox>
          <div :class="['el-col', 'el-col-' + 24 / optionsLayout]" style="display: flex; align-items: center">
            <el-checkbox
              v-model="selectValue"
              style="margin-bottom: 0"
              :key="getLastOption.value"
              :label="getLastOption.value"
              :disabled="readOnly || disabled"
            >
              {{ getLastOption.text }}
            </el-checkbox>
            <!-- 选中其他时候才会出现 -->
            <div style="flex: 1" v-if="selectValue.indexOf(getLastOption.value) > -1">
              <el-input size="small" v-model="getLastOption.attach" @change="getLastOption = getLastOption" placeholder="其他备注"></el-input>
            </div>
          </div>
        </template>
        <template v-else>
          <el-checkbox-group v-model="selectValue" class="el-row el-row--flex" :disabled="readOnly || disabled">
            <el-checkbox
              v-for="item in $items"
              :key="item.value"
              :class="['el-col', 'el-col-' + 24 / optionsLayout]"
              :label="item.value"
              :disabled="item.disabled"
            >
              {{ item.text }}
            </el-checkbox>
          </el-checkbox-group>
        </template>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
export default dsf.component({
  name: 'DsfAsqQuestionCheckbox',
  ctrlCaption: '多选题',
  mixins: [$mixins.asqQuestionItemsMixins],
  data() {
    return {
      multiple: true
    }
  }
})
</script>
