<!--
 * @Descripttion: 矩阵填空
 * @Author: zhanghang
 * @Date: 2022-03-07 09:28:28
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-07-14 10:27:14
-->
<template>
  <DsfAsqQuestionBody class="ds-question-table" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <el-form ref="form" :model="form" label-width="0">
        <el-table ref="table" :data="rowsData" border :header-cell-style="{ background: '#f5f7fa', color: '#606266' }">
          <!-- 占位 -->
          <el-table-column align="center" label="" width="180">
            <template v-slot="scope">
              {{ scope.row.text }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" align="center" v-for="(it, index) in columns" :label="it.text" :key="index">
            <template v-slot="scope">
              <el-form-item style="position: relative" :prop="`${scope.$index},${it.uuid}`" :rules="rules">
                <el-input
                  :disabled="readOnly || disabled" 
                  :size="scope.row.setting[it.uuid].inputsize"
                  :maxlength="scope.row.setting[it.uuid].max"
                  v-model="scope.row.model[it.uuid].value"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
/* value 数据结构
 * {行id-列id:{value:'',attach:''}}
 */
export default dsf.component({
  name: 'DsfAsqQuestionMatrixInput',
  ctrlCaption: '矩阵填空',
  mixins: [$mixins.asqQuestionMatrix],
  props: {
    stem: {
      type: String,
      default: '矩阵填空'
    },
    controlMode: {
      type: String,
      default: 'maxtrixInput'
    }
  },
  data() {
    return {
      form: {},
      rules: [
        {
          validator: (rule, value, callback) => {
            let [index, uuid] = rule.field.split(',')
            let _value = this.rowsData[index].model[uuid].value
            let r = this.rowsData[index].setting[uuid]
            if (r.required) {
              let isTrue = this.validator.required(_value)
              isTrue ? callback() : callback(this.MESSAGE_TYPE['required'])
            } else {
              callback()
            }
          }
        },
        {
          validator: (rule, value, callback) => {
            let [index, uuid] = rule.field.split(',')
            let _value = this.rowsData[index].model[uuid].value
            let r = this.rowsData[index].setting[uuid]
            let type = r.reg
            if (this.validator[type]) {
              let isTrue = this.validator[type](_value)
              isTrue ? callback() : callback(this.MESSAGE_TYPE[type])
            } else {
              callback()
            }
          }
        },
        {
          validator: (rule, value, callback) => {
            let [index, uuid] = rule.field.split(',')
            let _value = this.rowsData[index].model[uuid].value
            let r = this.rowsData[index].setting[uuid]
            if (r.min) {
              let isTrue = this.validator['min'](_value, r.min)
              isTrue ? callback() : callback(`该项至少${r.min}字`)
            } else {
              callback()
            }
          }
        }
      ]
    }
  },
  methods: {
    // 自定义表单校验
    async customVaildate() {
      return new Promise((resolve) => {
        if (this.$refs?.form?.validate) {
          this.$refs?.form
            .validate()
            .then((res) => resolve(res))
            .catch((err) => resolve(err))
        } else {
          resolve(true)
        }
      })
    }
  }
})
</script>
