<template>
  <DsfAsqQuestionBody class="ds-question-fill" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="fill-content" :class="{ isDesign: isDesign }">
        <el-input
          type="textarea"
          v-model="_value"
          :autosize="isDesign ? false : { minRows: rows }"
          :rows="rows"
          :placeholder="placeholder"
          :readonly="isDesign ? true : readOnly"
          :disabled="disabled"
          :clearable="clearable"
          :resize="resizeState"
          :maxlength="maxlength > 0 ? maxlength : false"
          :showWordLimit="maxlength > 0 ? showWordLimit : false"
        >
        </el-input>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>

<script>
export default dsf.component({
  name: 'DsfAsqQuestionFill',
  ctrlCaption: '填空题',
  mixins: [$mixins.asqQuestionControl],
  props: {
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get('question-fill-meta-data')
      }
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    rows: {
      type: [Number, String],
      default: '3'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    resizeState: {
      type: String,
      default: 'vertical'
    },
    maxlength: {
      type: [Number, String],
      default: ''
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    controlMode: {
      type: String,
      default: 'fill'
    }
  },
  data() {
    return {
      value2: ''
    }
  },
  computed: {
    _value: {
      get() {
        return this.value2
      },
      set(v) {
        if (dsf.isDef(v) && (this.maxlength > 0 ? v.length <= this.maxlength : true)) {
          this.emitValueChange(v)
        } else if (dsf.isDef(ov)) {
          this.emitValueChange(ov)
        }
      }
    }
  },
  watch: {
    value: {
      handler(v, ov) {
        if (dsf.isDef(v) && (this.maxlength > 0 ? v.length <= this.maxlength : true)) {
          this.value2 = v
        } else {
          this.value2 = ov
        }
      },
      immediate: true
    }
  }
})
</script>
