<!--
 * @Descripttion: 比重份额
 * @Author: zhanghang
 * @Date: 2021-12-07 17:13:06
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-16 17:09:38
-->
<template>
  <DsfAsqQuestionBody class="ds-question-proportion" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="ds-question-proportion-options">
        <p v-if="isLimitTotal">
          <span>比重总和：</span>
          <span v-if="proporty && value">{{ proporty }} /</span>
          {{ total }}
        </p>
        <div class="proportion-list">
          <div class="proportion-list-item" v-for="(item, index) in valueCopy" :key="index">
            <template>
              <div v-html="item.text"></div>
            </template>
            <Slider
              :isDesign="isDesign"
              :readOnly="readOnly"
              :disabled="disabled"
              :isShowTooltip="isShowTooltip"
              :marks="marks"
              :min="minValue"
              :isLimitTotal="isLimitTotal"
              :isMutual="isLimitTotal && valueCopy.length === 2"
              :index="index"
              :limit="maxValue"
              :carryover="carryover"
              :value.sync="item.score"
              :max="maxValue"
              @mutual="mutual"
            />
          </div>
        </div>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
import Slider from '../common/slider.vue'
export default dsf.component({
  name: 'DsfAsqQuestionProportion',
  ctrlCaption: '比重份额',
  mixins: [$mixins.asqQuestionControl, $mixins.asqQuestionDefaultOptions],
  components: {
    Slider
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    stem: {
      type: String,
      default: '比重题'
    },
    total: {
      type: Number,
      default: 100
    },
    //限制比重总和
    isLimitTotal: {
      type: Boolean,
      default: true
    },
    //限制比重最小/最大值
    isLimitBoundary: {
      type: Boolean,
      default: false
    },
    //是否显示数值
    isShowTooltip: {
      type: Boolean,
      default: false
    },
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 100
    },
    //是否乱序
    isShuffle: {
      type: Boolean,
      default: false
    },
    fixationEnd: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get('question-proporty-item-meta-data')
      }
    },
    controlMode: {
      type: String,
      default: 'proportion'
    }
  },
  created() {
    //初始化选项
    this.initValueCopy()
  },
  data() {
    return {
      marks: {},
      proporty: 0,
      //遗留的数量
      carryover: this.total,
      //值数组
      valueCopy: []
    }
  },
  computed: {
    $value: {
      get() {
        return this.value ? this.value : []
      }
    }
  },
  watch: {
    //选项变化重新设置valueCopy
    items: {
      handler() {
        this.initValueCopy()
      },
      deep: true
    },
    value: {
      handler(v) {
        //如果value存在更新valueCopy
        v && this.updateValueCopy(v)
      },
      deep: true
    },
    valueCopy: {
      handler(v) {
        //如果限制比重
        if (this.isLimitTotal && v.length !== 2) {
          this.carryover = v.reduce((l, cur) => l - cur.score, this.total)
          this.proporty = v.reduce((t, cur) => t + cur.score, 0)
        }
        this.updateValue(v)
      },
      deep: true
    },
    isLimitTotal(v) {
      if (v) {
        this.total = this.maxValue
      }
    },
    total: {
      handler(v, o) {
        //没有设置边界则最大值边界等于总和
        if (!this.isLimitBoundary) {
          this.maxValue = v
        } else if (v > this.items.length * this.maxValue) {
          dsf.layer.message('比重总和不能超过各项之和', false)
          this.$nextTick(() => (this.total = this.items.length * this.maxValue))
        } else if (v < this.maxValue) {
          dsf.layer.message('比重总和不能小于最大值', false)
          this.$nextTick(() => (this.total = this.maxValue))
        }
      }
    },
    minValue: {
      handler(v) {
        let split = Math.floor((this.maxValue - v) / 10)
        let marks = {}
        for (let i = this.minValue; i < this.maxValue; i += split) {
          if (i + split > this.maxValue) {
            marks[this.maxValue] = this.maxValue + ''
          } else {
            marks[i] = i + ''
          }
        }
        marks[this.maxValue] = this.maxValue + ''
        this.marks = marks
      },
      immediate: true
    },
    maxValue: {
      handler(v, o) {
        if (this.isLimitTotal && v > this.total) {
          dsf.layer.message('最大值不能超过比重总和', false)
          this.$nextTick(() => (this.maxValue = this.total))
          return false
        }
        let split = Math.floor((v - this.minValue) / 10)
        let marks = {}
        for (let i = this.minValue; i < v; i += split) {
          if (i + split > v) {
            marks[v] = v + ''
          } else {
            marks[i] = i + ''
          }
        }
        marks[v] = v + ''
        this.marks = marks
      },
      immediate: true
    }
  },
  methods: {
    $formItemInnerValidate() {
      let errors = []
      if (this.isLimitTotal && this.required) {
        let sum = this.$value.reduce((s, c) => s + c.score, 0)
        if (sum < this.total) errors.push({ message: '未达到比重总和' })
      }
      return errors
    },
    initValueCopy() {
      let items = this.items
      if (this.isShuffle && !this.isDesign) {
        if (this.fixationEnd) {
          items = [..._.shuffle(items.slice(0, items.length - 1)), ...items.slice(items.length - 1)]
        } else {
          items = _.shuffle(items)
        }
      }
      // 深度拷贝
      this.valueCopy = items.map((it) => {
        it.score = this.minValue
        it.value = it.value + ''
        return { ...it }
      })
    },
    //更新values
    updateValue(values) {
      values.forEach((it) => {
        let index = this.$value.findIndex((_it) => _it.value === it.value)
        if (index > -1) {
          let item = this.$value[index]
          item.score = it.score
        } else {
          this.$value.push(it)
        }
      })
      this.emitValueChange(this.$value)
    },
    //更新valueCopy
    updateValueCopy(values) {
      values.forEach((it) => {
        let index = this.valueCopy.findIndex((_it) => _it.value === it.value)
        if (index > -1) {
          let item = this.valueCopy[index]
          item.score = it.score
        }
      })
    },
    mutual({ index, score }) {
      this.proporty = this.total
      let item = this.valueCopy[1 - index]
      item.score = this.total - score
      this.$set(this.valueCopy, 1 - index, item)
    }
  }
})
</script>
