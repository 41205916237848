<!--
 * @Descripttion: 统计
 * @Author: zhanghang
 * @Date: 2022-01-18 10:30:17
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-25 15:35:59
-->
<template>
  <div class="ds-control ds-page">
    <div class="statistics-wrap">
      <div class="statistics-head">{{ asqName }}</div>
      <div class="statistics-content">
        <div class="statistics-left">
          <p>数据概况</p>
          <div class="data-list">
            <div class="list-item list-item-01">
              <p>今日新增</p>
              <p>{{ todaySubmitNum }}</p>
            </div>
            <div class="list-item list-item-02">
              <p>数据总量</p>
              <p>{{ submitNum }}</p>
            </div>
            <div class="list-item list-item-03">
              <p>平均答题时长</p>
              <p>{{ submitAvgTime }}</p>
            </div>
          </div>
        </div>
        <div class="statistics-right">
          <el-tabs v-model="active.value" class="statistics-right-title">
            <el-tab-pane label="统计报表" name="1">
              <DsfAsqQuestionReportForm  :isDesign="isDesign" @getData="getData"  />
            </el-tab-pane>
            <el-tab-pane label="数据汇总" name="2">
              <DsfAsqQuestionDataCollect :isDesign="isDesign" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DsfAsqQuestionDataCollect from '../statistics/dataCollect'
export default dsf.component({
  name: 'DsfAsqQuestionStatistics',
  ctrlCaption: '问卷统计',
  mixins: [$mixins.control],
  provide(){
    return {
      active:this.active
    }
  },
  components: {
    DsfAsqQuestionDataCollect
  },
  data() {
    return {
      id:'',
      active:{
        value:'1'
      },
      //问卷名称
      asqName: '',
      //提交数目
      submitNum: '',
      //今日提交数
      todaySubmitNum: '',
      //答题时长
      submitAvgTime: '',
    }
  },
  created() {
  },
  methods: {
    
    getData(data) {
      let result = data.args
      let { submitNum, todaySubmitNum, submitAvgTime, name } = result
      this.submitNum = submitNum
      this.todaySubmitNum = todaySubmitNum
      this.submitAvgTime = submitAvgTime
      this.asqName = name
    }
  }
})
</script>
