<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-16 16:27:05
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-16 17:07:12
-->
<template>
  <DsfAsqQuestionBody  class="ds-question-select" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <el-select v-model="selectValue" :disabled="readOnly || disabled" :multiple="multiple" placeholder="请选择">
        <el-option v-for="item in $items" :key="item.value" :label="item.text" :value="item.value" :disabled="item.disabled" />
      </el-select>
    </template>
  </DsfAsqQuestionBody>
</template>

<script>
export default dsf.component({
  name: "DsfAsqQuestionSelect",
  ctrlCaption: "下拉框",
  mixins: [$mixins.asqQuestionItemsMixins],
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    stem: {
      type: String,
      default: '下拉题'
    }
  }
});
</script>