<!--
 * @Descripttion: 自增表格
 * @Author: zhanghang
 * @Date: 2022-02-28 14:03:38
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-07-14 10:29:45
-->
<template>
  <DsfAsqQuestionBody class="ds-question-table" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <el-form ref="form" :model="form" label-width="0">
        <el-table ref="table"  :data="rowsData" border :header-cell-style="{ background: '#f5f7fa', color: '#606266' }">
          <el-table-column align="center" min-width="180" v-for="(item,$index) in $items" :key="$index"  :label="item.text">
            <template slot-scope="scope">
              <el-form-item class-name="custon-column" :prop="`${scope.$index},${item.uuid}`" v-if="item.type == '2'" :rules="rules">
                <el-select :disabled="readOnly || disabled"  v-model="scope.row[item.uuid].value" placeholder="请选择">
                  <el-option v-for="it in item.seting" :key="it.value" :label="it.text" :value="it.value"> </el-option>
                </el-select>
              </el-form-item>

              <el-form-item style="position: relative" :prop="`${scope.$index},${item.uuid}`" v-else :rules="rules">
                <el-input :disabled="readOnly || disabled"  v-model="scope.row[item.uuid].value"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" v-if="rowsData.length > defaultRows" fixed="right" label="操作" width="80">
            <template v-slot="scope">
              <DsfButton
                v-if="scope.$index >= defaultRows"
                :disabled="readOnly || disabled" 
                btn-style="icon-text"
                title="删除"
                @click="remove(scope)"
              >删除</DsfButton
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <DsfButton icon="hao" class="add-btn" :disabled="readOnly || disabled"  size="small" v-if="!isDesign" @click="getNewItem">新增填空</DsfButton>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
import { validator, MESSAGE_TYPE,types as _types } from '_question/utils/validator'
const types = [
  {
    text: '填空项',
    value: '1'
  },
  {
    text: '下拉单选',
    value: '2'
  },
  ..._types
]
/* value 数据结构 [value]选中项 [attach]选项输入框内容
 * [{列id:{value:'',attach:''}}]
 */
export default dsf.component({
  name: 'DsfAsqQuestionTable',
  ctrlCaption: '自增表格',
  mixins: [$mixins.asqQuestionControl, $mixins.asqQuestionDefaultOptions],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    metadata: {
      type: Object,
      default() {
        let md = dsf.metadata.get('question-table-item-meta-data')
        return md
      }
    },
    stem: {
      type: String,
      default: '自增表格'
    },
    controlMode: {
      type: String,
      default: 'table'
    },
    // 最少行数
    minRows: {
      type: [String, Number],
      default: 1
    },
    // 最多行数
    maxRows: {
      type: [String, Number],
      default: 20
    },
    // 默认行数
    defaultRows: {
      type: [String, Number],
      default: 2
    }
  },
  computed: {
    $value: {
      get() {
        return this.value
      },
      set(value) {
        console.log(value)
        console.log(this.metadata)
        this.emitValueChange(value)
      }
    },
    $items(){
     return this.items
    }
  },
  watch: {
    $items: {
      handler(v) {
        let required = false
        v.forEach((it) => {
          if (it.required) {
            required = true
          }
        })
        //输入项目 有一项必填则必填
        this.$emit('update:required', required)

        if (this.isDesign) {
          this.createRowData(undefined, true)
          this.$forceUpdate();
        }
      },
      immediate: true,
      deep: true
    },
    $value: {
      handler(value) {
        if (value && value.length > 0) {
          let row = this.rowsData
          // 行数少于数据则增加行数
          if (row.length < value.length) {
            let r = value.length>this.maxRows?(this.maxRows-row.length):(value.length - row.length)
            this.createRowData(r)
          }
          value.forEach((it, index) => {
            for (let uuid in it) {
              row[index][uuid].value = it[uuid].value
              row[index][uuid].attach = it[uuid].attach
            }
          })
        }
      },
      deep: true
    },
    rowsData: {
      handler(v) {
        let value = []
        v.forEach((it) => {
          let row = {}
          for (let key in it) {
            row[key] = { ...it[key] }
          }
          value.push(row)
        })
        this.$value = value
      },
      deep: true
    },
    defaultRows(val) {
      if (this.isDesign) {
        this.createRowData(val, true)
      }
    }
  },
  data() {
    return {
      tableStyle: {
        width: '100%'
      },
      types,
      form: {},
      rowsData: [],
      rules: [
        {
          validator: (rule, value, callback) => {
            let [index, uuid] = rule.field.split(',')
            let _value = this.rowsData[index][uuid].value
            let r = this.items.find((it) => it.uuid === uuid)
            if (r.required) {
              let isTrue = validator.required(_value)
              isTrue ? callback() : callback(MESSAGE_TYPE['required'])
            } else {
              callback()
            }
          }
        },
        {
          validator: (rule, value, callback) => {
            let [index, uuid] = rule.field.split(',')
            let _value = this.rowsData[index][uuid].value
            let r = this.items.find((it) => it.uuid === uuid)
            let type = r.type
            if (validator[type]) {
              let isTrue = validator[type](_value)
              isTrue ? callback() : callback(MESSAGE_TYPE[type])
            } else {
              callback()
            }
          }
        }
      ]
    }
  },
  created() {
    this.createRowData(undefined, true)

  },
  methods: {
    //创建行数据
    createRowData(defaultRows = this.defaultRows, reset = false) {
      let row = []

      row = new Array(defaultRows).fill('').map((it) => {
        let model = {}
        this.items.forEach((it) => {
          model[it.uuid] = {
            value: '',
            attach: ''
          }
        })
        return model
      })
      if (reset) {
        this.rowsData = row
      } else {
        this.rowsData.push(...row)
      }
    },
    // 自定义校验方式
    async customVaildate() {
      return new Promise((resolve) => {
        if (this.$refs?.form?.validate) {
          this.$refs?.form
            .validate()
            .then((res) => resolve(res))
            .catch((err) => resolve(err))
        } else {
          resolve(true)
        }
      })
    },
    //增加选项
    getNewItem() {
      if (this.rowsData.length === this.maxRows) {
        dsf.layer.message('以达到最大行数', false)
        return false
      }
      this.createRowData(1)
    },
    //移除选项
    remove(scope) {
      let orgin = this.rowsData
      orgin.splice(scope.$index, 1)
      this.rowsData = orgin
    }
  }
})
</script>
