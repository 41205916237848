<template>
  <DsfAsqQuestionBody class="ds-question-horzfill" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div
        v-if="isDesign"
        contenteditable="true"
        class="designer-question-editor designer-question-editor-focus"
        @focus="focusHandler($event)"
        @blur="blurHandler($event)"
        v-html="_content"
      ></div>
      <span v-if="isDesign" class="fontsize"> 注：输入中文的（） 则为插入输入框 </span>
      <div v-else ref="box" class="ds-horzfill-content">
        <template v-for="(c, i) in contentArray">
          <span v-if="c.text" v-html="c.text" :key="'span' + i"></span>
          <template v-else>
            <span v-html="c" :key="'span' + i"></span>
            <input
              class="horzfill-input"
              :readonly="readOnly"
              :disabled="disabled"
              v-model="value2['fill' + (i + 1)]"
              :key="'input' + i"
              :ref="'fill' + (i + 1)"
              @input="inputHandler('fill' + (i + 1))"
            />
          </template>
        </template>
      </div>
      <span ref="fontsize" class="fontsize">{{ fontsize }}</span>
    </template>
  </DsfAsqQuestionBody>
</template>

<script>
export default dsf.component({
  name: 'DsfAsqQuestionHorzFill',
  ctrlCaption: '横向填空',
  mixins: [$mixins.asqQuestionControl],
  props: {
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get('question-fill-meta-data')
      }
    },
    stem: {
      type: String,
      default: '请填写以下信息'
    },
    content: {
      type: String,
      default: '姓名（）年龄（）岁<br/>联系方式（）'
    },
    value: {
      type: [String, Object, Array],
      default: null
    },
    contentArray: {
      type: Array, //通过设计器计算一遍后存储，就不用在效果页面计算了
      default() {
        return []
      }
    },
    controlMode: {
      type: String,
      default: 'horzFill'
    }
  },
  data() {
    return {
      focus: false,
      value2: {},
      fontsize: '',
      oldLogicIgnore: false
    }
  },
  created() {
    this._content = this.unescapeHTML(this.content)
  },
  computed: {
    _content: {
      get() {
        return this.content
      },
      set(v) {
        this.$emit('update:content', v)
      }
    }
  },

  updated() {
    if (this.$refs.fontsize && this.logicIgnore != this.oldLogicIgnore) {
      this.setInputWidth(this.value)
    }
    this.oldLogicIgnore = this.logicIgnore
  },
  watch: {
    content: {
      handler(v) {
        if (this.isDesign) {
          this.metadata.fill_desc = v
        }
      },
      immediate: true
    },
    value: {
      handler(v) {
        if (dsf.isDef(v)) {
          if (dsf.isObject(v) && !dsf.isEmptyObject(v)) {
            this.value2 = dsf.mix(this.value2, v)
            this.setInputWidth(v)
          } else if (dsf.isString(v)) {
            this.value2['fill1'] = v
            this.$nextTick(() => {
              this.inputHandler('fill1', true)
            })
          }
        } else {
          _.forEach(this.contentArray, (c, i) => {
            if (!c?.text) {
              this.value2['fill' + (i + 1)] = null
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    unescapeHTML(escapedHTML) {
      //只转义br标签
      return escapedHTML.replace(/&lt;br/g, '<br').replace(/&gt;/g, '>')
    },
    getContentArray() {
      let reg = /（）/gi,
        contentArray = [],
        startIndex = 0,
        inputCount = 1,
        self = this

      let content = this._content.replace(/<div(([\s\S])*?)<\/div>/gi, (str, endIndex, _content) => {
        let _str = str.substring(5, str.length - 6)
        str = '<br/>' + _str
        return str
      })
      content.replace(reg, (str, endIndex, _content) => {
        contentArray.push(_content.substring(startIndex, endIndex))
        startIndex = endIndex + str.length
        self.value2['fill' + inputCount] = null
        inputCount++
      })
      if (startIndex < content.length) {
        contentArray.push({
          text: content.substring(startIndex, content.length)
        })
      }
      this.$emit('update:contentArray', contentArray)
      return contentArray
    },
    inputHandler(key, onlySetW) {
      this.fontsize = this.value2[key]
      if (!onlySetW) {
        // if (this.contentArray.length <= 1) {
        //   this.emitValueChange(this.fontsize)
        // } else {
          this.emitValueChange(this.value2)
        // }
      }
      this.$nextTick(() => {
        let width = this.$refs.fontsize.offsetWidth
        this.$refs[key][0].style.width = width + 'px'
      })
    },
    focusHandler() {
      this.focus = true
    },
    blurHandler(e) {
      this._content = e.currentTarget.innerHTML
      this.focus = false
    },
    async setInputWidth(v) {
      for (let k in v) {
        this.fontsize = v[k]
        await this.setInputWidth2(k)
      }
    },
    setInputWidth2(key) {
      let _this = this
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          let width = _this.$refs.fontsize.offsetWidth
          _this.$refs[key][0].style.width = width + 'px'
          resolve(width)
        })
      })
    }
  },
  design: {
    metadata: {
      isDeep: false,
      create(root) {
        let res = $mixins.formControl.design.metadata.create.call(this, root)
        res.metadata.asqType = 'fill'
        let contentArray = this.getContentArray()
        let metadata = dsf.metadata.get('question-fill-meta-data')
        res.metadata.valueAttributes = []
        if (contentArray.length > 0) {
          _.forEach(contentArray, (c, i) => {
            if (!c?.text) {
              let obj = _.cloneDeep(metadata.valueAttributes[0])
              obj.code = 'fill' + (i + 1)
              obj.name = '填空项' + (i + 1)
              res.metadata.valueAttributes.push(obj)
            }
          })
        }
        return res
      }
    }
  }
})
</script>
