/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-06 13:50:46
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-05-06 18:05:54
 */
export default {
  async system_question_save(args) {
    let vm = args._self
    let form = vm.$form;
    if (form) {
      let options = {
        isRefresh: true, //是否要刷新父页面
        customParams: args.params[1] || "",
        savedClose: args.params[2] === true,
        successMsg: args.params[3] || "保存成功",
        errorMsg: args.params[4] || "保存失败"
      };
      if (!form.$isPrototype) {
        form.$set(form.$viewData, 'use_time', vm.use_time)

        // 自定义内嵌表单验证
        for (let i = 0; i < form.allComponents.length; i++) {
          let comp = form.allComponents[i]

          if (comp.customVaildate) {
            //缓存重置前的验证
            if (!comp.isResetValidate) {
              comp.cacheValidate = comp.$formItemInnerValidate
            }
            let r = await comp.customVaildate()
            if (!r) {
              comp.$formItemInnerValidate = function () {
                return {
                  message: ''
                }
              }
              // 重置过的验证
              comp.isResetValidate = true
            } else {
              comp.$formItemInnerValidate = comp.cacheValidate
            }
          }
        }


        let result = await form.formSave(options);
        if (result) {
          let params = dsf.mix({}, vm.$route.query, {
            _id: result.data._id,
            asqInfoId: result.data.asq_info_id,
            scope_value: result.data.scope_value //问卷类型，公开：0
          });
          //成功页面
          vm.$router.replace({
            path: '/pc/asq/info/submitsuccess',
            query: params
          });

        }
      }

      else {
        dsf.layer.message(options.successMsg)
      }
    }
  }
}