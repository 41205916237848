<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-09 11:07:45
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-02 15:04:13
-->
<template>
  <div style="padding: 8px; text-align: center">
    <DsfButton icon="guanbi2" v-if="isReadOnly&&!isPreView" type="plain" @click="close()"> 关闭 </DsfButton>
    <DsfButton icon="baocun" v-else :disabled="isPreView" @click="save()">提交</DsfButton>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfAsqQuestionButtons',
  ctrlCaption: '问卷按钮',
  props: {
    show: {
      type: Boolean,
      defautl: 0
    },
    isPreView: {
      type: Boolean,
      defautl: false
    }
  },
  data() {
    return {
      //开始答题时间
      startTimes: 0,
      //答题花费时间
      use_time: 0,
      isReadOnly: false
    }
  },
  mixins: [$mixins.control],
  mounted() {},
  created() {

    this.startTimes = new Date().getTime()
    this.$watch(
      function () {
        return this.queryString.isview
      },
      function (newVal, oldVal) {
        // 做点什么
        if (newVal) {
          this.isReadOnly = true
        } else {
          this.isReadOnly = false
        }
      },
      {
        immediate: true
      }
    )
  },
  methods: {
    save() {
      let buttons = _.keyBy(dsf.buttons.getPcButtons(), 'actionName')
      let button = buttons['system_question_save']
      this.use_time = parseInt((new Date().getTime() - this.startTimes) / 1000)
      if (button) {
        button.handler.call(this, {
          _self: this,
          params: []
        })
      }
    },
    close() {
      let buttons = _.keyBy(dsf.buttons.getPcButtons(), 'actionName')
      let button = buttons['system_close_window']
      if (button) {
        button.handler.call(this, {
          _self: this,
          params: []
        })
      }
    }
  }
})
</script>
